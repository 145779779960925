.MuiFormLabel-root {
  color: #70BEE6 !important;
}

label.Mui-focused {
  color: #70BEE6;
}

/* MuiOutlinedInput-root {
  fieldset {
    border-color: "white";
  }
  :hover fieldset {
    border-color: "white";
  }
} */
